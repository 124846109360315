import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { IoCallOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ValidationForgotPinScreen1 } from "../../../Components/Yup";
import { useNavigate } from "react-router-dom";
import Buttons from "../../../Components/Buttons";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from "../../../constant";
import Notiflix from 'notiflix';
import { CiLock } from "react-icons/ci";
import { ServerStyleSheet } from 'styled-components';
import serviceFunctions from '../../../Services';
export const Screen1 = ({ setLoginMode, mobile }) => {

    const [isSubmit, setIsSubmit] = useState(false);

    const navigate = useNavigate();

    // console.log(`Forgot Pin Screen`, mobile, setLoginMode);


    const formInitialValues = {
        number: mobile || "",
    };

    const { handleChange, values, handleBlur, errors, touched, handleSubmit } =

        useFormik({

            initialValues: formInitialValues,
            validationSchema: ValidationForgotPinScreen1,

            onSubmit: async (values) => {

                try {

                    // console.log({ values });
                    if (!mobile)
                        handleResendOtp(values.number)
                    else {
                        let data = await serviceFunctions.handleDirectLogin({ mobile });
                        if (data.success) {
                            localStorage.setItem("mobile", values.number.toString());
                            localStorage.setItem("token", data.accessToken);
                            navigate("/");
                        }

                    }
                } catch (err) {
                    return err.message;
                }

            },
        });


    const handleResendOtp = async (mobile) => {

        Notiflix.Loading.circle();


        let data = await serviceFunctions.handleResendOtp({ mobile });

        localStorage.setItem("mobile", mobile);

        if (data?.success) {
            // toast.info(response.data.message, { duration: 3000 });
            navigate("/verification?pin_reset=true");
        } else {
            toast.error(data, { duration: 3000 });
        }

        Notiflix.Loading.remove();

    }




    return (
        <>
            <div className="subscribe">
                <Toaster />
                <div className="navigater">
                    <FaArrowLeftLong onClick={() => navigate(-1)} />
                    <p onClick={() => navigate("/help")}>Help</p>
                </div>

                <div className="mc-note">

                    <h2><strong>NOTE</strong></h2>

                    <p>
                        You need an active subscription to receive OTP for PIN reset.
                        Click How to subscribe for information.
                    </p>

                </div>

                <div className='mc-get-my-pin-container'>
                    <button className='mc-get-my-pin' onClick={() => navigate("/plans")}>How to Subscribe</button>
                </div>

                <div className='dashed-line'></div>

                <div className="msisdn">
                    <p> Enter your phone number</p>
                </div>
                <div className="userDetails mb-3">
                    <form onSubmit={handleSubmit} autoComplete="off">

                        <div className="formSpaces mt-2">
                            <div className="icons">
                                <IoCallOutline />
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="number"
                                    placeholder="MSISDN"
                                    name="number"
                                    disabled={mobile}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                                        handleChange({
                                            target: {
                                                name: 'number',
                                                value: numericValue,
                                            },
                                        });
                                    }}

                                    value={values.number}
                                />
                                <label htmlFor="number">Phone Number</label>
                            </div>
                        </div>

                        {errors.number && touched.number ? (
                            <span style={{ color: "red" }}>{errors.number}</span>
                        ) : null}

                        <Buttons
                            value={"Send OTP"}
                            type="submit"
                            name="submit"
                            disabled={isSubmit}
                            className="btn btn-info mt-2" />
                    </form>

                    <div className='mc-text-right'>
                        <p className='text-right' onClick={() => setLoginMode(1)}>Login with pin ?</p>
                    </div>

                </div>
            </div>
        </>
    );
};
