import React, { useEffect, useState } from 'react';
import axios from 'axios';
import serviceFunctions from '../Services';
import Notiflix from 'notiflix';

const Headers = () => {
    const [headers, setHeaders] = useState({});

    useEffect(() => {
        const fetchHeaders = async () => {
            try {
                Notiflix.Loading.circle();
                const response = await axios.get('http://wazospin.co/');
                Notiflix.Loading.remove();
                setHeaders(response.headers);
            } catch (error) {

                Notiflix.Loading.remove();
                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                console.error('Error fetching headers:', error);
            }
        };

        fetchHeaders();
    }, []);

    return (
        <div>
            <h1>HTTP Headers</h1>
            <pre>{JSON.stringify(headers, null, 2)}</pre>
        </div>
    );
};

export default Headers;