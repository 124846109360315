import React from 'react';
import { useFormik } from "formik";
import { IoCallOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Validate } from "../Components/Yup";
import { baseUrl } from "../constant";
import axios from "axios";
import Buttons from "../Components/Buttons";
import toast, { Toaster } from 'react-hot-toast';
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Notiflix from 'notiflix';
import TermsCondition from '../Components/TermsCondition';
import serviceFunctions from '../Services';
import { CiLock } from "react-icons/ci";
const Subscription = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const navigate = useNavigate();
  const formInitialValues = {
    name: "",
    number: "",
    enter_pin: "",
    verify_pin: "",
    termsAndConditions: false,
  };
  const { handleChange, values, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: Validate,

      onSubmit: async (values) => {

        Notiflix.Loading.circle();

        await axios.post(`${baseUrl}/user/register`, {
          mobile: String(values.number),
          username: values.name,
          enter_pin: values.enter_pin,
          verify_pin: values.verify_pin
        })
          .then(response => {

            if (response.data.success) {

              setIsSubmit(true);
              toast.success(response.data.message, { duration: 3000 });
              setTimeout(() => navigate('/verification'), 3000);
              localStorage.setItem('mobile', values.number);

            } else {

              toast.error(response.data.message, { duration: 4000 });

              setIsSubmit(false);
            }

            Notiflix.Loading.remove();

          })

          .catch(error => {

            if (error?.response?.status == 401) {
              serviceFunctions.handleLogout();
            }

            toast.error(error.response.data.message, { duration: 4000 })
            console.error(error);
          });
        Notiflix.Loading.remove();
      },
    });

  Notiflix.Loading.init({
    backgroundColor: 'rgba(0,0,0,0.5)',
    svgColor: '#73ddff ',
    clickToClose: false,
  });

  return (
    <>
      <div className="subscribe">
        <Toaster />
        <div className="navigater">
          <FaArrowLeftLong onClick={() => navigate(-1)} />
          <p onClick={() => navigate("/help")}>Help</p>
        </div>
        <div className="msisdn">
          <p> Enter Your name, Phone number and PIN</p>
        </div>
        <div className="userDetails mb-3">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="formSpace">
              <div className="icon">
                <CiUser />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter your name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                <label htmlFor="name">Enter your name</label>
              </div>
            </div>
            {errors.name && touched.name ? (
              <span style={{ color: "red" }}>{errors.name}</span>
            ) : null}

            <div className="formSpaces mt-2">
              <div className="icons">
                <IoCallOutline />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="MSISDN"
                  name="number"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                    handleChange({
                      target: {
                        name: 'number',
                        value: numericValue,
                      },
                    });
                  }}

                  value={values.number}
                />
                <label htmlFor="number">Phone Number</label>
              </div>
            </div>
            {errors.number && touched.number ? (
              <span style={{ color: "red" }}>{errors.number}</span>
            ) : null}


            <div className="formSpace mt-2">
              <div className="icon">
                <CiLock />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter PIN"
                  name="enter_pin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.enter_pin}
                />
                <label htmlFor="name">Enter 4 Digit PIN</label>
              </div>
            </div>
            {errors.enter_pin && touched.enter_pin ? (
              <span style={{ color: "red" }}>{errors.enter_pin}</span>
            ) : null}



            <div className="formSpace mt-2">
              <div className="icon">
                <CiLock />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Verify PIN"
                  name="verify_pin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.verify_pin}
                />
                <label htmlFor="name">Verify 4 Digit PIN</label>
              </div>
            </div>
            {errors.verify_pin && touched.verify_pin ? (
              <span style={{ color: "red" }}>{errors.verify_pin}</span>
            ) : null}



            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="termsAndConditions"
                name="termsAndConditions"
                checked={values.termsAndConditions}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label className="form-check-label termsConditions" for="termsAndConditions" onClick={handleShow}>
                Terms & Condition
              </label>
            </div>
            {errors.termsAndConditions && touched.termsAndConditions ? (
              <span style={{ color: "red" }}>{errors.termsAndConditions}</span>
            ) : null}
            <Buttons
              value="Continue"
              type="submit"
              name="submit"
              className="btn btn-info mt-2"
              disabled={isSubmit} />
          </form>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Condition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TermsCondition />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Subscription;
