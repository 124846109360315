export default function GloSubscription() {

    const plans = [
        {
            qty: 1,
            cost: "N50",
            type: "Daily/Auto Renewal",
            keyword: "1SPD",
            shortCode: "577"
        },
        {
            qty: 2,
            cost: "N100",
            type: "Daily/Auto Renewal",
            keyword: "2SPD",
            shortCode: "577"
        },
        {
            qty: 5,
            cost: "N250",
            type: "Daily/Auto Renewal",
            keyword: "5SPD",
            shortCode: "577"
        }
    ]

    const mtlplans = [
        {
            qty: 1,
            cost: "N50",
            type: "Daily/Auto Renewal",
            keyword: "1SP",
            shortCode: "20558"
        },
        {
            qty: 2,
            cost: "N100",
            type: "Daily/Auto Renewal",
            keyword: "2SP",
            shortCode: "20558"
        },
        {
            qty: 5,
            cost: "N250",
            type: "Daily/Auto Renewal",
            keyword: "5SP",
            shortCode: "20558"
        }
    ]

    const airtelPlans = [
        {
            qty: 1,
            cost: "N50",
            type: "Daily/Auto Renewal",
            keyword: "1SP",
            shortCode: "20558"
        },
        {
            qty: 2,
            cost: "N100",
            type: "Daily/Auto Renewal",
            keyword: "2SP",
            shortCode: "20558"
        },
        {
            qty: 5,
            cost: "N250",
            type: "Daily/Auto Renewal",
            keyword: "5SP",
            shortCode: "20558"
        }
    ]

    return <>
        <div className='mc_glo_container'>

            <div className="mc-pack-list-container">

                <div className="mc-logo-container">
                    <img src="/images/glo.png" className="mc-img-container" />
                </div>

                <div className="text-container">
                    <p>
                        To buy <strong className="mc-green">SPINS</strong>, SMS <strong className="mc-blue">KEYWORD</strong> to 577.<br />
                        e.g. To buy <strong className="mc-green">1 SPINS</strong>, SMS <strong className="mc-blue">1SPD</strong> to 577.
                    </p>
                </div>

                <table
                    className="mc-glo-table"
                >

                    <tr
                        className="mc-head"
                    >
                        <td><strong>SPINS to Buy</strong></td>
                        <td><strong>Cost</strong></td>
                        <td><strong>Type</strong></td>
                        <td><strong>Keyword</strong></td>
                        <td><strong>Short Code</strong></td>
                    </tr>


                    {plans.map((item) =>
                        <tr className="mc-body">
                            <td className="one">{item.qty}</td>
                            <td className="two">{item.cost}</td>
                            <td className="three">{item.type}</td>
                            <td className="four">{item.keyword}</td>
                            <td className="five">{item.shortCode}</td>
                        </tr>
                    )}

                </table>

            </div>

            {/* <div className="mc-pack-list-container">

                <div className="mc-logo-container">
                    <img src="/images/mtn.jpg" className="mc-img-container" />
                </div>

                <div className="text-container yellow">
                    <p>
                        To buy <strong className="mc-green">SPINS</strong>, SMS <strong className="mc-blue">KEYWORD</strong> to 20558.<br />
                        e.g. To buy <strong className="mc-green">2 SPINS</strong>, SMS <strong className="mc-blue">2SP</strong> to 20558.
                    </p>
                </div>

                <table
                    className="mc-glo-table"
                >

                    <tr
                        className="mc-head yellow"
                    >
                        <td><strong>SPINS to Buy</strong></td>
                        <td><strong>Cost</strong></td>
                        <td><strong>Type</strong></td>
                        <td><strong>Keyword</strong></td>
                        <td><strong>Short Code</strong></td>
                    </tr>


                    {mtlplans.map((item) =>
                        <tr className="mc-body">
                            <td className="one">{item.qty}</td>
                            <td className="two">{item.cost}</td>
                            <td className="three">{item.type}</td>
                            <td className="four">{item.keyword}</td>
                            <td className="five">{item.shortCode}</td>
                        </tr>
                    )}

                </table>

            </div>

            <div className="mc-pack-list-container">

                <div className="mc-logo-container">
                    <img src="/images/airtel.png" className="mc-img-container" />
                </div>


                <div className="text-container red">
                    <p>
                        To buy <strong className="mc-green">SPINS</strong>, SMS <strong className="mc-blue">KEYWORD</strong> to 20558.<br />
                        e.g. To buy <strong className="mc-green">5 SPINS</strong>, SMS <strong className="mc-blue">5SP</strong> to 20558.
                    </p>
                </div>

                <table
                    className="mc-glo-table"
                >

                    <tr
                        className="mc-head red"
                    >
                        <td><strong>SPINS to Buy</strong></td>
                        <td><strong>Cost</strong></td>
                        <td><strong>Type</strong></td>
                        <td><strong>Keyword</strong></td>
                        <td><strong>Short Code</strong></td>
                    </tr>


                    {airtelPlans.map((item) =>
                        <tr className="mc-body">
                            <td className="one">{item.qty}</td>
                            <td className="two">{item.cost}</td>
                            <td className="three">{item.type}</td>
                            <td className="four">{item.keyword}</td>
                            <td className="five">{item.shortCode}</td>
                        </tr>
                    )}

                </table>

            </div> */}
        </div>
    </>
}