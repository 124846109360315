import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useFormik } from "formik";
import axios from "axios";
import { baseUrl } from "../constant";
import toast, { Toaster } from 'react-hot-toast';
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';
export default function AddBank() {
    let token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [bankData, setBankData] = useState([''])


    const formik = useFormik({
        initialValues: {
            name: "",
            bankacc: "",
            bankcode: "",
            type: "",
            currency: ""
        },
        onSubmit: async (values) => {
            Notiflix.Loading.circle()
            try {
                const response = await axios.post(
                    `${baseUrl}/user/create_transfer_recepient`,
                    {
                        type: values.type,
                        name: values.name,
                        account_number: values.bankacc,
                        bank_code: values.bankcode,
                        currency: values.currency
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                // console.log("API Response:", response.data);

                if (response.data.success) {
                    toast.success(response.data.message)
                    setTimeout(() => navigate('/my-wallet'), 3000)
                    Notiflix.Loading.remove();
                } else {
                    toast.error(response.data.message, { duration: 4000 })
                    Notiflix.Loading.remove();
                }
            } catch (error) {

                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                toast.error(error.response.data.message, { duration: 4000 })


            }
        },
    });
    const fetchAccountName = async (accNum, bankcode) => {
        Notiflix.Loading.circle()
        try {
            const response = await axios.post(`${baseUrl}/user/resolve`, {
                account_number: accNum,
                bank_code: bankcode
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            // console.log("fetchAccountName", response)
            if (response.data.success) {
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    name: response.data.data.data.account_name,
                    type: response.data.data.data.type,
                    currency: response.data.data.data.currency
                }));
                toast.success(response.data.message)
                Notiflix.Loading.remove();
            } else {
                toast.error(response.data.message, { duration: 4000 })
                Notiflix.Loading.remove();
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            toast.error(error?.response?.data?.message || "An error occurred while fetching account name.", { duration: 4000 })

            Notiflix.Loading.remove();

        }
    };


    const handleBankSelect = async (event) => {
        const selectedBankId = event.target.value;
        const selectedBank = bankData.find((bank) => bank.id === Number(selectedBankId));

        if (selectedBank) {
            formik.setValues((prevValues) => ({
                ...prevValues,
                bankcode: selectedBank.bank_code,
                type: selectedBank.type,
                currency: selectedBank.currency
            }));
        } else {
            formik.setValues((prevValues) => ({
                ...prevValues,
                bankcode: "",
                type: "",
                currency: ""
            }));
        }

        // console.log(formik.values.bankacc, "account bankacc");
        // console.log(selectedBank.bank_code, "bank codec check");

        await fetchAccountName(formik.values.bankacc, selectedBank.bank_code);
    };



    useEffect(() => {
        Notiflix.Loading.circle()
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/user/get_BankCode`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBankData(response.data.data);
                Notiflix.Loading.remove();
            } catch (error) {

                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                console.error(error.response.data.message, error);
                Notiflix.Loading.remove();
            }
        }

        fetchData();
    }, []);

    return (
        <div className='addBank'>
            <Toaster />
            <FaArrowLeftLong onClick={() => navigate(-1)} className='close' />
            <div className="mt-2 title">
                <p>Add Bank Account</p>
            </div>
            <div className="addNote">
                <p>
                    Please note you can only add a bank account once. Be cafeful to fill the form with the right bank details.
                </p>
            </div>
            <form onSubmit={formik.handleSubmit} autoComplete="off" id='bankform'>
                <div className="userInfo">

                </div>
                <div>
                    <div className="mb-1">
                        <label className="form-label">Bank Account Number</label>
                        <input
                            type="text"
                            className="form-control"
                            name="bankacc"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.bankacc}
                            placeholder="Bank account number"
                            required
                        />
                    </div>
                </div>
                <div>
                    <div className="mb-1">
                        <label className="form-label">Select bank</label>
                        <select
                            name="selectedBank"
                            id="selectedBank"
                            className='form-select'
                            onChange={(e) => {
                                handleBankSelect(e);
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.selectedBank}
                            required
                        >
                            <option value="" >Select Bank</option>
                            {bankData.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.bank_name}
                                </option>
                            ))}

                        </select>
                    </div>
                </div>
                <div className="mb-1">
                    <label className="form-label">Account Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Account Name"
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        required
                    />
                </div>
                <div>
                    <div className="mb-1">
                        <label className="form-label">Bank Code</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bank code"
                            name="bankcode"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.bankcode}
                            readOnly
                        />
                    </div>
                </div>
                <div className="currency row">
                    <div className="leftpart col-6">
                        <label className="form-label">Currency Type</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type"
                            name="type"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.type}
                            readOnly
                        />
                    </div>
                    <div className="rightpart col-6">
                        <label className="form-label">Currency</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Currency"
                            name="currency"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.currency}
                            readOnly
                        />
                    </div>
                </div>
                <div className="submitBtn mt-3">
                    <button className="btn btn-primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}
