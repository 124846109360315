import axios from "axios";
import { baseUrl } from "../constant";

const serviceFunctions = {
    handleLogout: () => {
        localStorage.removeItem("token");
        window.location.reload();
    },
    handleResendOtp: async ({ mobile }) => {
        try {

            const response = await axios.post(`${baseUrl}/user/resendOtp`,
                {
                    mobile,
                }
            );

            return response.data;

        } catch (err) {
            return err.message;
        }
    },
    handleDirectLogin: async ({ mobile }) => {
        try {

            const response = await axios.post(`${baseUrl}/user/login?direct=true`, {
                mobile,
            });

            return response.data;

        } catch (err) {
            return err.message;
        }
    },
    checkIfUserExist: async ({ mobile }) => {
        try {
            const response = await axios.get(`${baseUrl}/user/isUser/${mobile}`);

            return response.data;

        } catch (err) {
            return null;
        }
    }
}


export default serviceFunctions;