import React from 'react';
import Loading from '../Components/Loading';

const LoadingPage = () => {

    return (
        <>
            <div className="loadinpage">

                <div className="wheelimg">
                    <img src="images/halfspin.png" alt={"spinlogo"} />
                </div>

                <div className="gamelogo">
                    <img src="images/logoimg.png" alt={"gamelogo"} />
                </div>

                <div className="progressBar">
                    <div className="progressBarInner">
                        <Loading />
                    </div>
                </div>
            </div>


        </>
    );
};

export default LoadingPage;
