import React from 'react';

import { useNavigate } from "react-router-dom";
const TrialOver = () => {
    const navigate = useNavigate();
    return (
        <>
            <div id="last">

                <div className="box">
                    <div className="box_content">
                        <label>
                            <strong>Your trial is over.</strong>
                            <p className='trialtext'>Now subscribe for your chance to win up to
                                <b> N1,000,000</b> now!</p>
                        </label>
                        <button onClick={() => navigate('/subscription')}>Register</button>
                    </div>
                </div>
                <div className="win_image">
                    <img src="images/gift.png" alt={"gift"} />
                </div>
            </div>
        </>
    );
};

export default TrialOver;
