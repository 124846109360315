import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { TbCurrencyNaira } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import Buttons from "../Components/Buttons";
import { baseUrl } from "../constant";
import axios from 'axios';
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';
export default function Wallet() {
    const [wallet, setWallet] = useState('')
    const [detail, setDetail] = useState('')
    const [bankDetail, setBankDetail] = useState('')
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState('')
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    let token = localStorage.getItem("token");
    const [availableSpin, setAvailableSpin] = useState('0')

    useEffect(() => {
        Notiflix.Loading.circle();


        try {


            axios.get(`${baseUrl}/user/user_profile`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                    if (!response.data.data.verification_status) {
                        navigate("/auth");
                    } else {

                        let verification_time = new Date(response.data.data.verification_time).getTime();

                        let today = (new Date()).getTime();

                        const diff = today - verification_time;

                        if (diff / (1000 * 60 * 60) > 24) {
                            navigate("/auth");
                        } else {
                            setIsLoaded(true);
                        }

                    }


                    setAvailableSpin(response.data.data.available_spins)
                    localStorage.setItem("network", response.data.data.network);
                    // console.log("user_profile", response.data.data)
                    Notiflix.Loading.remove();
                })
                .catch(error => {

                    if (error?.response?.status == 401) {
                        serviceFunctions.handleLogout();
                    }
                    console.error(error.response.data.message, error);
                    Notiflix.Loading.remove();
                });

        } catch (err) {
            Notiflix.Loading.remove();
        }

    }, []);

    const fetchData = async () => {
        Notiflix.Loading.circle()
        try {
            const response = await axios.get(`${baseUrl}/user/wallet`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setWallet(response.data.data);
            Notiflix.Loading.remove();

        } catch (error) {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            Notiflix.Loading.remove();
            console.error("Error fetching data:", error.message);
        }
    };
    const fetchBankData = async () => {
        try {

            Notiflix.Loading.circle();

            const response = await axios.get(`${baseUrl}/user/bank_details`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            Notiflix.Loading.remove();
            // console.log('Data: my data', response.data.data);
            if (response.data.data.active == 1) {
                setDetail(response.data.success)
                setStatus(response.data.data.active)
                setShow(true)
                setBankDetail(response.data.data);
            }


        } catch (error) {

            Notiflix.Loading.remove();

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            console.error("Error fetching data:", error.message);
        }
    };
    const fetchBankDetails = async () => {
        try {

            Notiflix.Loading.circle();
            const response = await axios.get(`${baseUrl}/user/payment_detail`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            Notiflix.Loading.remove();
            // console.log('Data:', response.data.data);

        } catch (error) {

            Notiflix.Loading.remove();

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchData();
        fetchBankData();
        fetchBankDetails();
    }, [])

    const addBank = () => {
        Notiflix.Loading.circle()
        setTimeout(() => {
            navigate("/add-bank");
            Notiflix.Loading.remove();
        }, 500);

    };

    const Withdraw = () => {
        Notiflix.Loading.circle()
        setTimeout(() => {
            navigate("/withdraw");
            Notiflix.Loading.remove();
        }, 500);

    };

    return (
        <>
            {isLoaded && <div className='wallletpage'>
                <div className='d-flex justify-content-between walletHelp'>
                    <IoClose className="close" onClick={() => navigate("/spin-wheel")} />
                    <p onClick={() => navigate("/help")} >Help</p>
                </div>

                <div className="mt-2 title">
                    <p>Wallet</p>
                </div>

                <div className="box">
                    <div className="balance">
                        <div>
                            <h1 className="m-0"><TbCurrencyNaira className="niaraCurr" /><span>{wallet ? wallet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</span></h1>
                            <p className="mb-0">Current Balance</p>
                        </div>

                    </div>
                </div>

                <div className="totalSpin my-2">
                    <div className="content">
                        <p>Available  Spins</p>
                    </div>
                    <div className="number"><p>{availableSpin ? availableSpin : '0'}</p></div>

                </div>

                <div className="spinWheelBtn">

                    {!detail && (
                        <Buttons
                            value="Add bank Account"
                            className="btn btn-info my-2"
                            onClick={addBank}
                        />
                    )}

                    <Buttons
                        value="Click to Withdraw"
                        className="btn btn-warning"
                        disabled={!detail}
                        onClick={Withdraw}
                    />
                    {show && (
                        <div className='addNote '>
                            <p>Withdrawal will be to your registered account below.</p>
                            <div className="banknote my-2">
                                <p><strong>Bank Name:</strong> {bankDetail?.bank_name} </p>
                                <p><strong>Account No:</strong> {bankDetail?.account_number}</p>
                                <p><strong>Account Name:</strong> {bankDetail?.account_name}</p>
                            </div>
                            <p>To re-register Bank Account click <span onClick={() => navigate("/contact-us")}>“Here”.</span></p>
                        </div>
                    )}
                </div>
            </div>}
        </>
    )
}
