import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../constant';
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';

export default function WinHistory() {
    let token = localStorage.getItem("token");
    const [data, setData] = useState([])

    // Function to format the date
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {


        try {




            const interval = setTimeout(() => {

                Notiflix.Loading.circle()
                axios.get(`${baseUrl}/user/win_history`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then((response) => {
                    setData(response.data.data)
                    // // console.log(response.data.data)
                    Notiflix.Loading.remove();
                }).catch((error) => {
                    // // console.log(error)

                    if (error?.response?.status == 401) {
                        serviceFunctions.handleLogout();
                    }

                    Notiflix.Loading.remove();
                })

            }, 10);

            return () => clearInterval(interval);
        } catch (err) {
            // // console.log('err', err.message);
        }
    }, [])




    return (
        <>
            {
                data.map((data, index) => (
                    <div key={index} className='d-flex tableparent'>
                        <div className='table'>
                            <p>S.no.</p>
                            <p>{index + 1}</p>
                        </div>
                        <div className='table'>
                            <p>Date</p>
                            <p>{formatDate(data.current_date)}</p>
                        </div>
                        <div className='table'>
                            <p>Amount</p>
                            <p>N{data.win_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                        </div>
                        <div className='table'>
                            <p>Status</p>
                            <p style={{ color: getStatusColor(data.status) }}>{(data.status == 0) ? 'Loss' : 'Winning'}</p>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

function getStatusColor(status) {
    switch (status) {
        case '1':
            return '#109E38';

        case '0':
            return '#FF0000';

        default:
            return '';
    }
}

