import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";


import WinnerList from "../Components/WinnerList";
import ContactUs from "../Components/ContactUs";
export default function Help() {
  const costToPlay = () => {
    return <div>
      <ul>
        <li>N100 for 2 SPINS/day.</li>
        <li>N50 for 1 SPIN/day. </li>
        <li>N250 for 5 SPINS/Week</li>
      </ul></div>
  }
  const playGame = () => {
    return <div>
      <div style={{ paddingLeft: "4px" }}>
        <div >
          <strong style={{ textDecoration: "underline" }}>On Globacom Network</strong>
          <p>SMS “2SP” @N100/Day for 2 SPINS</p>
          <p>or</p>
          <p>SMS “1SP” @ N50/Day for 1 SPIN to 577 </p>
        </div>
        <div >
          <strong style={{ textDecoration: "underline" }}>On MTN/Airtel</strong>
          <p>SMS “2SP” @N100/Day for 2 SPINS</p>
          <p>or</p>
          <p>SMS “1SP” @ N50/Day for 1 SPIN SPIN to 20558</p>
        </div>
        <p>Click on the URL on the transaction SMS to play the game.</p>
      </div>
    </div>
  }
  const canIwin = () => {
    return <div>Available to be won on an instant basis are the following-
      <ol>
        <li>N100</li>
        <li>N1,000</li>
        <li>N10,000</li>
        <li>N100,000</li>
        <li>N1,000,000</li>
      </ol></div>
  }
  const faqData = [
    {
      question: "What is Spin4Cash?",
      answer:
        "Spin4Cash is an instant win Gaming service that rewards subscribers with Cash prizes. Subscribers will purchase a SPIN to Spin a Wheel. If the pointer lands on a prize, subscriber wins the prize.",
    },
    {
      question: "How much does it cost to play?",
      answer: costToPlay()

    },
    {
      question: "How do I play the game?",
      answer: playGame()
    },
    {
      question: "What can I win? ",
      answer: canIwin()
    },
    {
      question: "How do I know if I am a winner? ",
      answer:
        "When you spin the wheel and the pointer of the wheel falls on a prize, you have won that prize and will get a notification.",
    },
    {
      question: "How do I claim my prize?",
      answer:
        "The cash won will be paid into your Spin4Cash wallet. You will simply go to the withdrawal page on the portal, add your bank details and then transfer the cash prize to your bank account.",
    },
    {
      question: "HHow long do I have to claim my prize?",
      answer:
        "Cash prizes have to be claimed withing 6 months of winning the cash prize. Any money not redeemed will be forfeited in line with NLRC rules and regulations.",
    },
    {
      question: "Who can participate in Spin4Cash?",
      answer: "18 years old and above. ",
    },
    {
      question: "How long does it take to receive my prize?",
      answer: "Withdrawals will happen instantly on request.",
    },
    {
      question: "I have no bank account, what can I do?",
      answer:
        "You can setup a mobile money account with your phone number. Please contact us to discuss how to manage this.",
    },
    {
      question: "I am having issues; how can I get help?",
      answer:
        "Please reach ot to us through the contact page for issue resolution.",
    },
    {
      question: "How do I unsubscribe from service?",
      answer:
        "Go to “Your Profile” page of the portal and click on unsubscribe. ",
    },
  ];


  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();



  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="helps">

      <div className="navigater">
        <IoClose onClick={() => navigate(-1)} />
      </div>
      <div className="logo">
        <img src="images/logoimg.png" alt="logo" />
      </div>
      <div className="mainimage">
        <img src="images/main-image.png" alt="mainimg" />
      </div>
      <div className="slogon text-center">
        <h1>
          <i>SPIN NOW! WIN NOW!</i>
        </h1>
        <h4>
          <i>
            N1,000,000, N100,000, N10,000, N1,000, N100 <br />
            UP FOR GRABS.
          </i>
        </h4>
      </div>
      <hr class="hrline"></hr>
      <div className="howtoplay">
        <div className="introduction text-center">
          <h3>
            How to play <i>Spin4Cash</i>
          </h3>
          <p>
            <i>Spin4Cash</i> is an instant win gaming service that <br />
            reward subscribers with cash prices up to N1,000,000. To play <br />
            follow the steps below.
          </p>
        </div>
        <div className="rule px-4">
          <div className="rules mb-3">
            <div className="img">
              <img src="images/phone.png" alt="phone" />
            </div>
            <div>
              <p>
                SMS “2SP” for 2 Spins/day @ N100 or “1SP” for 1 Spin/day @ N50 to 577 on Glo network and 20588 on MTN and Airtel.
              </p>
            </div>
          </div>
          <div className="rules mb-3">
            <div className="img">
              <img src="images/login.png" alt="phone" />
            </div>
            <div>
              <p>
                Go to Spin4Cash.co (not spin4cash.com)to login or register.
              </p>
            </div>
          </div>
          <div className="rules mb-3">
            <div className="img">
              <img src="images/wheel.png" alt="phone" />
            </div>
            <div>
              <p>
                Use available SPINs to SPIN the Wheel, what you win, you cash
                out.
              </p>
            </div>
          </div>
          <div className="rules mb-3">
            <div className="img">
              <img src="images/24.png" alt="phone" />
            </div>
            <div>
              <p>Payment is instantly.​</p>
            </div>
          </div>
        </div>
      </div>
      <hr class="hrline"></hr>
      <div className="row justify-content-center text-center">
      <h3 className="text-center">Video Info</h3>
        <iframe height="250px" src="https://www.youtube.com/embed/Hfp4bD6T0eM?si=h16RLcOS71dFf_5I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <hr class="hrline"></hr>
      <div className="px-4">
        <WinnerList />
      </div>
      <hr class="hrline"></hr>
      <div className="faq">
        <h3 className="text-center text-bold py-3">FAQ</h3>
        <div className="faqs text-center px-4">
          {faqData.map((item, index) => (
            <div
              className={`info p-4 mb-3 ${index === activeIndex ? "active" : "inactive"
                }`}
              key={index}
              onClick={() => toggleAnswer(index)}
              style={{
                cursor: "pointer",
                border: index === activeIndex ? "none" : "1.8px dashed #878787",
                backgroundColor: index === activeIndex ? "#DEFCFF" : "white",
              }}
            >
              <p className="bigcontent">{item.question}</p>
              {activeIndex === index && (
                <p className="smallcontent">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      <ContactUs />

      <div className="text-center footer mb-2">
        <img src="images/logoimg.png" alt="logo" />
        <p>Regulated by</p>
        <p><img src="images/ncrclogo.png" style={{ width: "50px" }} /></p>
      </div>
    </div>
  );
}
