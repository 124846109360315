import React from 'react'
import ContactUs from '../Components/ContactUs'
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export default function ContactPage() {
    const navigate = useNavigate();
    return (
        <div className='contactusPage'>
            <div className='close-button'>
                <IoClose onClick={() => navigate(-1)} />
            </div>
            <ContactUs />
        </div>
    )
}
