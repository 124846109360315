import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import axios from 'axios';
import { baseUrl } from "../constant";
import Notiflix from 'notiflix';
import GloSubscription from '../Components/Subsciptions/Glo';
import AirtelSubscription from '../Components/Subsciptions/Airtel';
import serviceFunctions from '../Services';
import { v4 as uuidv4 } from 'uuid';

const generateUuid = () => {
    return uuidv4();
};

const BuySpins = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(0);
    const [data, setData] = useState([]);
    let token = localStorage.getItem("token");
    let network = localStorage.getItem("network");


    useEffect(() => {
        const fetchData = async () => {
            Notiflix.Loading.circle()
            try {
                const response = await axios.get(`${baseUrl}/user/get-subscription-packs`);
                setData(response.data.data);
                // console.log(response.data.data, "Fetched Data");
                Notiflix.Loading.remove();
            } catch (error) {

                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                // console.log(error, "api error");
                Notiflix.Loading.remove();
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        Notiflix.Loading.circle();
        if (network === "mtn") {
            document.getElementById("mtn-payment-page").click();
        } else {
            Notiflix.Loading.remove();
        }
    }, [network]);

    const sendBuyPrice = async () => {
        Notiflix.Loading.circle()
        try {
            const response = await axios.post(`${baseUrl}/user/user-subscribed-pack`, {
                select: selectedOption
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success(response.data.message, { duration: 3000 });

            // console.log({ response: response.data.data })

            if (response.data.data.subscription_id)
                localStorage.setItem("subscription_id", response.data.data.subscription_id);

            setTimeout(() => navigate('/confirmation'), 3000);
            // console.log(response.data.message, "spinwheel");
            Notiflix.Loading.remove();
        } catch (error) {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            toast.error(error.response.data.message, { duration: 4000 })
            Notiflix.Loading.remove();
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        // console.log("Selected Option:", event.target.value); // Debugging line
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendBuyPrice();
    };

    const autoRenewPacks = data.filter(option => option.auto_renewal === "YES");
    const nonRenewPacks = data.filter(option => option.auto_renewal !== "YES");
    const selectedOptionData = data.find(option => option.pack_name === selectedOption);
    const pricePoint = selectedOptionData ? selectedOptionData.price_point : '';

    return (
        <div className='buy_spins'>
            <Toaster />
            <FaArrowLeftLong onClick={() => navigate(-1)} className='close' />
            <div className="mt-2 title">

                <p>Buy More Spins</p>
                {
                    network === "glo" && <img src='./images/glo.png' className='mc-buy-more-head-img' alt='' />
                }
                 {
                    network === "airtel" && <img src='./images/airtel.png' className='mc-buy-more-head-img' alt='' />
                }
            </div>

            {/* {network !== "glo" && <>

                <form onSubmit={handleSubmit} autoComplete="off">
                    <div>
                        <h6>Auto Renewal Packs</h6>
                        {autoRenewPacks.map(option => (
                            <div key={option.id} className='buy_spins_form'>
                                <input
                                    type="radio"
                                    value={option.id}
                                    checked={selectedOption == option.id}
                                    onChange={handleOptionChange}
                                    id={`auto - ${ option.id }`}
                                />
                                <label htmlFor={`auto - ${ option.id }`} className={selectedOption == option.id ? 'selected' : ''}>
                                    {`${ option.pack } @${ option.price_point }`}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h6>One-Off Packs</h6>
                        {nonRenewPacks.map(option => (
                            <div key={option.id} className='buy_spins_form'>
                                <input
                                    type="radio"
                                    value={option.id}
                                    checked={selectedOption == option.id}
                                    onChange={handleOptionChange}
                                    id={`non - ${ option.id }`}
                                />
                                <label htmlFor={`non - ${ option.id }`} className={selectedOption == option.id ? 'selected' : ''}>
                                    {`${ option.pack } @${ option.price_point }`}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="submitBtn mt-3 text-center">
                        <button className="btn btn-primary py-2 text-white" type="submit">
                            Pay {pricePoint}
                        </button>
                    </div>
                </form>
            </>} */}

            {
                network === "glo" && <GloSubscription />
            }

{
                network === "airtel" && <AirtelSubscription />
            }

            {
                network === "mtn" && <a href={`https://ng-app.com/NinaJojer/spin4cash-on-demand-landing-en-doi-web?origin_banner=3&trxId=${generateUuid()}&trfsrc=secureD`} id='mtn-payment-page'></a>
            }
        </div >
    );
};

export default BuySpins;
