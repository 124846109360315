import React from 'react';
import { useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Buttons from "../Components/Buttons";
import toast, { Toaster } from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import axios from "axios";
import { baseUrl } from '../constant';
import serviceFunctions from '../Services';
import Notiflix from 'notiflix';


export default function WithdrawVerification() {
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate();
    const mobileNumber = localStorage.getItem('mobile');
    let token = localStorage.getItem("token");
    const formInitialValues = {
        otp: ''
    };


    const { handleChange, values, handleSubmit } = useFormik({
        initialValues: formInitialValues,
        onSubmit: async (values) => {

            Notiflix.Loading.circle();
            await axios.post(`${baseUrl}/user/finalize_transfer`, {
                otp: values.otp
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {

                Notiflix.Loading.remove();
                if (response.data.success) {
                    setIsSubmit(true)
                    toast.success(response.data.message, { duration: 3000 })
                    setTimeout(() => navigate('/congrats'), 3000);

                } else {
                    setIsSubmit(false)
                    toast.error(response.data.message, { duration: 4000 })

                }
            })
                .catch(error => {

                    Notiflix.Loading.remove();

                    if (error?.response?.status == 401) {
                        serviceFunctions.handleLogout();
                    }
                    toast.error("An error occurred. Please try again.", { duration: 4000 })
                    console.error(error);
                });
        }
    });




    return (
        <div className="verification">
            <Toaster />
            <div className="navigater">
                <FaArrowLeftLong onClick={() => navigate(-1)} />
                <p onClick={() => navigate("/help")}>Help</p>
            </div>

            <div className="content">
                <p>Enter Your Verification</p>
                <p>code</p>
                <span>We sent it to {mobileNumber}</span>
            </div>

            <div className="getOtp">
                <form className='w-100' onSubmit={handleSubmit}>
                    <OtpInput
                        value={values.otp}
                        onChange={(otp) => handleChange('otp')(otp)}
                        numInputs={6}
                        name='otp'
                        renderInput={(props) => <input {...props} type="number" />}
                    />

                    <Buttons
                        value='Submit'
                        className='btn btn-info mt-2'
                        type="submit"
                        disabled={isSubmit}
                    />
                </form>
            </div>


        </div>
    );
}
