import axios from 'axios';
import { baseUrl } from '../constant';

export const logRequest = async (payload) => {
    try {
        const response = await axios.post(`${baseUrl}/user/log-request`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log('Log request response:', response.data);
    } catch (error) {
        console.error('Error logging request:', error);
    }
};
