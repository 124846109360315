import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
    let token = localStorage.getItem('token');
    const location = useLocation();
    const isHomeOrLoading = location.pathname === '/home' || location.pathname === '/loading';

    if (!token && !isHomeOrLoading) {
        return <Navigate to="/home" />;
    }
    return children;
};

export default ProtectedRoute;
