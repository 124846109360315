import { IoClose } from "react-icons/io5";
import { LiaEdit } from "react-icons/lia";
import WinHistory from "../Components/WinHistory";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useFormik } from "formik";
import { Profiles } from "../Components/Yup";
import { baseUrl } from "../constant";
import axios from "axios";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Notiflix from 'notiflix';
import { duration } from "@mui/material";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import serviceFunctions from "../Services";

export default function Profile() {
    const [base64Image, setBase64Image] = useState("");
    const [userData, setUserdata] = useState({})
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ableToSubscribe, setAbleToSubscribe] = useState(false);
    const [subscription_pack, setSubscriptionPack] = useState();
    const handleShow = () => {
        setShow(true);
    };


    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;

                setBase64Image(base64String);
            }

            reader.readAsDataURL(file);
        }
    }

    let token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const { user_id } = decoded;
    const navigate = useNavigate()



    useEffect(() => {


        try {


            const interval = setTimeout(() => {


                Notiflix.Loading.circle()
                axios.get(`${baseUrl}/user/user_profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        setUserdata(response.data.data);
                        localStorage.setItem("network", response.data.data.network);
                        // console.log("gsfgs", response)
                        Notiflix.Loading.remove();
                    })
                    .catch(error => {
                        console.error(error.response.data.message, error);
                        Notiflix.Loading.remove();
                    });


            }, 10);



            return () => clearInterval(interval);
        } catch (Err) {

        }

    }, [token, user_id, show]);


    const formInitialValues = {
        username: userData.username,
    };


    const { handleChange, values, handleBlur, errors, touched, handleSubmit } =
        useFormik({
            initialValues: formInitialValues,
            validationSchema: Profiles,
            onSubmit: async (values) => {
                setLoading(true);
                Notiflix.Loading.circle();
                try {
                    const response = await axios.post(
                        `${baseUrl}/user/profile`,
                        {
                            user_id: user_id,
                            username: values.username,
                            image: base64Image,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    Notiflix.Loading.remove();

                    // console.log(response);

                    if (response.data.success) {
                        toast.success(response.data.message, { duration: 3000 })
                        setShow(false);
                    } else {
                        toast.error(response.data.message, { duration: 4000 })

                    }
                } catch (error) {

                    Notiflix.Loading.remove();

                    if (error?.response?.status == 401) {
                        serviceFunctions.handleLogout();
                    }

                    toast.error("An error occurred. Please try again.", { duration: 4000 })
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
        });


    const logoutSession = () => {
        serviceFunctions.handleLogout();
    }

    const checkSubscriptionStatus = async () => {

        try {

            Notiflix.Loading.circle();

            let response = await axios.get(`${baseUrl}/user/subscription-status`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            Notiflix.Loading.remove();

            // console.log({ data: response.data });

            if (response.status === 200) {
                if (response.data.data?.subscription_status) {

                    setSubscriptionPack(response.data.data.subscription_pack);
                    setAbleToSubscribe(true);
                } else {
                    setAbleToSubscribe(false);
                }
            }

        } catch (err) {

            Notiflix.Loading.remove();

            if (err?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            return err.message;
        }

    }

    useEffect(() => {

        const interval = setTimeout(() => {

            checkSubscriptionStatus();

        }, 10);

        return () => clearInterval(interval);

    }, [])


    const unsubscribe = async () => {
        try {

            Notiflix.Loading.circle();
            const response = await axios.post(`${baseUrl}/user/unsubscribe`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // console.log({ response: response.data });

            if (response.status === 200) {
                // console.log("executed");
                toast.success(response.data.message, { duration: 3000 })
            } else {
                toast.info(response.data.message, { duration: 3000 })
            }

            Notiflix.Loading.remove();

        } catch (err) {

            if (err?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            Notiflix.Loading.remove();
            toast.error(err.message, { duration: 3000 })

            return err.message;
        }
    }

    return (
        <>
            <div className="profilePage">
                <div className="d-flex justify-content-between ">
                    <IoClose className="close" onClick={() => navigate(-1)} />
                    <p onClick={logoutSession} className="m-0 logout">Logout</p>

                </div>

                <div className="mt-2 title d-flex justify-content-between align-items-center">

                    {ableToSubscribe &&
                        <button
                            className="mc-unsubscribe-btn current-pack"
                        >
                            Subscribed To: <br />
                            <p>
                                {subscription_pack?.pack}
                            </p>
                        </button>
                    }

                    {ableToSubscribe &&
                        <button
                            onClick={() => unsubscribe()}
                            className="mc-unsubscribe-btn"
                        >
                            Unsubscribe
                        </button>
                    }
                </div>
                <div className="mt-2 title d-flex justify-content-between">
                    <p>Profile</p>
                    <p onClick={() => navigate("/help")} className="profilehelp">Help</p>

                </div>
                <div className="info">
                    <div className="information text-center">
                        <img src={userData.image ? userData.image : "images/avatar.png"} alt="" />
                        <div className="d-flex align-items-center justify-content-center userinfo">
                            <p className="m-0 pe-1">{userData ? userData.username : ""}</p>
                            <LiaEdit onClick={handleShow} />
                        </div>
                        <p className="m-0 userNum">{userData.mobile}</p>
                    </div>
                </div>
                <div className="histroy">
                    <p className="my-3 title">Winning History</p>
                    <WinHistory />
                </div>
            </div>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="mb-3">
                            <label className="col-form-label">username</label>
                            <input
                                className="form-control"
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                id="username"
                                name="username"
                                placeholder="Enter User name"
                                required
                            />


                        </div>
                        {errors.username && touched.username ? (
                            <span style={{ color: "red" }}>{errors.username}</span>
                        ) : null}
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                                Upload Image
                            </label>
                            <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                name="myImage"
                                onChange={handleImage}
                            />
                        </div>
                        <button
                            className="btn btn-info mt-2"
                            type="submit"
                            name="submit"
                            disabled={loading}
                        >
                            {loading ? 'Updating...' : 'Update'}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>


            <ToastContainer />
        </>
    )
}
