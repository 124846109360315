import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import Buttons from '../Components/Buttons';
import { useFormik } from 'formik';
import { Toaster } from 'react-hot-toast';
import { ValidationOtp } from '../Components/Yup';
import serviceFunctions from '../Services';

export default function Auth() {

    const [isLoaded, setIsLoaded] = useState(true);
    const [subscription_pack, setSubscriptionPack] = useState("");
    const [isSubmit, setIsSubmit] = useState("");
    const [enterOtpForm, setEnterOtpForm] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {

        const interval = setTimeout(() => {
            Notiflix.Loading.circle();
            setIsLoaded(false);
            fetchSubscriptionStatus();
            fetchUserProfile();
        }, 10);

        return () => clearInterval(interval);
    }, []);

    const fetchUserProfile = async () => {
        try {

            Notiflix.Loading.circle();

            let res = await axios.get(`${baseUrl}/user/user_profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            })

            Notiflix.Loading.remove();

            if (res) {
                console.log(res.data.data);
                if (res.data.data.verification_status) {

                    let verification_date = new Date(res.data.data.verification_time).getTime();

                    let today = new Date().getTime();
                    console.log(verification_date, today);

                    let diff = today - verification_date;

                    console.log(diff / (1000 * 60 * 60));
                    if (diff / (1000 * 60 * 60) < 24) {
                        navigate("/my-wallet");
                    } else {
                        setEnterOtpForm(true);
                        let res = await serviceFunctions.handleResendOtp({ mobile: localStorage.getItem("mobile") });

                    }

                } else {
                    setEnterOtpForm(true);
                    let res = await serviceFunctions.handleResendOtp({ mobile: localStorage.getItem("mobile") });
                }
            }

            setTimeout(() => {
                Notiflix.Loading.remove();
            }, 1000)

        } catch (err) {

            Notiflix.Loading.remove();
            if (err?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            return err.message;
        }
    }

    const fetchSubscriptionStatus = async () => {
        try {

            Notiflix.Loading.circle();

            let response = await axios.get(`${baseUrl}/user/subscription-status`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            Notiflix.Loading.remove();

            if (response) {
                if (response.data?.data.subscription_status) {
                    setSubscriptionPack(response.data.data.subscription_pack.pack)
                }
            } else {
                return null;
            }

        } catch (err) {
            Notiflix.Loading.remove();
            return null;
        }
    }

    const formInitialValues = {
        otp: '',
    };


    const { handleChange, values, handleSubmit, errors, handleBlur } = useFormik({

        initialValues: formInitialValues,
        validationSchema: ValidationOtp,
        onSubmit: async (values) => {

            try {

                Notiflix.Loading.circle();

                await axios.post(`${baseUrl}/user/mobile-otp-verify`, {
                    mobile: localStorage.getItem("mobile"),
                    otp: values.otp,
                }).then(response => {

                    if (response.data.success) {
                        setIsSubmit(true)
                        toast.success(response.data.message);
                        setTimeout(() => navigate('/my-wallet'), 3000);
                        localStorage.setItem('token', response.data.accessToken);
                    } else {
                        setIsSubmit(false)
                        toast.error(response.data.message, { duration: 4000 })
                    }
                    Notiflix.Loading.remove();
                })
                    .catch(error => {

                        if (error?.response?.status == 401) {
                            serviceFunctions.handleLogout();
                        }

                        toast.error(error.response.data.message, { duration: 4000 })
                        console.error(error);
                        Notiflix.Loading.remove();
                    });
            } catch (err) {
                toast.error(err.response.data.message, { duration: 4000 })
                console.error(err);
                Notiflix.Loading.remove();
            }
        }
    });






    return (
        <>
            {!isLoaded && <div className='mc-page-container auth-page'>
                <Toaster />
                <header className='mc-game-header'>
                    {/* <img src='images/logoimg.png' alt='' className='mc-logo' /> */}
                    <h1>NOTE</h1>
                </header>
                <section className='mc-section-proceed p-0'>

                    <div>
                        <h3>To withdraw cash, we need to know it is you.
                            Subscription is needed to receive OTP.
                        </h3>
                    </div>

                    <div className='mc-your-subscription'>
                        <span>Your subscription is:</span>
                        <button>{subscription_pack && subscription_pack}</button>
                    </div>

                    <div className='mc-click-container'>
                        <button>To Subscribe, Click here</button>
                    </div>

                    {enterOtpForm && <form className='w-100' onSubmit={handleSubmit}>

                        <div className="getOtp">

                            <div className='mc-enter-otp-text'>
                                <h2>Enter OTP sent to</h2>
                                <h1>{localStorage.getItem("mobile")}</h1>
                            </div>

                            <div className='mc-otp-input'>

                                <OTPInput
                                    value={values.otp}
                                    onChange={(otp) => handleChange('otp')(otp)}
                                    numInputs={4}
                                    name='otp'
                                    renderInput={(props) => <input
                                        {...props}
                                        type="number"
                                    />}
                                />

                                {errors.otp ? (
                                    <span style={{ color: "red" }}>{errors.otp}</span>
                                ) : null}

                            </div>

                        </div>

                        <Buttons
                            value='Submit'
                            className='btn btn-info mt-2'
                            type="submit"
                        />

                    </form>}

                </section>
            </div >}
        </>
    )
}
