import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { IoCallOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Validation } from "../Components/Yup";
import { useLocation, useNavigate } from "react-router-dom";
import Buttons from "../Components/Buttons";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from "../constant";
import Notiflix from 'notiflix';
import { CiLock } from "react-icons/ci";
import { ForgotPinScreen1 } from '../Components/Forms';
import { logRequest } from '../Helper';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  const [msisdnFromHeaders] = useState("");
  const navigate = useNavigate();
  const router = useQuery();

  const [loginMode, setLoginMode] = useState(1);

  useEffect(() => {

    const interval = setTimeout(() => {

      router.get("register") === "true" && setLoginMode(2);


      (async () => {
        if (router.get("token")) {
          await logRequest({
            href: window?.location?.href,
            token: router.get("token"),
            event: "user_came_with_token"
          });
        } else {
          await logRequest({
            href: window?.location?.href,
            token: router.get("token"),
            event: "user_came_without_token"
          });
        }
      })();



      let token = router.get("token");

      if (token) {
        localStorage.setItem("token", token);
        navigate("/spin-wheel");
      }
    }, 10);

    return () => clearInterval(interval);

  }, [navigate, router]);

  const formInitialValues = {
    number: "",
    pin: ""
  };

  const { handleChange, values, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: Validation,

      onSubmit: async (values) => {
        Notiflix.Loading.circle()
        await axios.post(`${baseUrl}/user/login`, {
          mobile: String(values.number),
          pin: values.pin,
        })
          .then(response => {
            // console.log(response);
            Notiflix.Loading.remove();

            if (response.data.success) {
              setIsSubmit(true);
              toast.success(response.data.message, { duration: 3000 });

              localStorage.setItem("token", response.data.accessToken);
              localStorage.setItem('mobile', values.number);
              navigate("/");

              // setTimeout(() => navigate('/verification'), 3000);


            } else {
              toast.error(response.data.message, { duration: 4000 })
              setIsSubmit(false);
            }

          })
          .catch(error => {
            toast.error(error.response.data.message, { duration: 4000 });
            console.error(error);
            Notiflix.Loading.remove();
          });
      },
    });

  return (
    <>
      {loginMode === 1 && <div className="subscribe">
        <Toaster />
        <div className="navigater">
          <FaArrowLeftLong onClick={() => navigate(-1)} />
          <p onClick={() => navigate("/help")}>Help</p>
        </div>

        <div className="mc-note">

          <h2><strong>NOTE</strong></h2>

          <p>
            Enter the 4 Digit PIN you set during registration. Click &quot;Get My PIN&quot; if you don&apos;t remember it.
          </p>



        </div>

        <div className='mc-get-my-pin-container'>
          <button className='mc-get-my-pin' onClick={() => setLoginMode(2)}>Get My Pin</button>
        </div>

        <div className='dashed-line'></div>


        <div className="msisdn">
          <p> Enter Phone Number and PIN</p>
        </div>
        <div className="userDetails mb-3">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="formSpaces mt-2">
              <div className="icons">
                <IoCallOutline />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="MSISDN"
                  name="number"
                  disabled={msisdnFromHeaders}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                    handleChange({
                      target: {
                        name: 'number',
                        value: numericValue,
                      },
                    });
                  }}
                  // formik using for number
                  value={values.number}
                />
                <label htmlFor="number">Phone Number</label>
              </div>
            </div>
            {errors.number && touched.number ? (
              <span style={{ color: "red" }}>{errors.number}</span>
            ) : null}

            <div className="formSpaces mt-2">
              <div className="icons">
                <CiLock />
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="4 Digit PIN"
                  name="pin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pin}
                />
                <label htmlFor="number">4 Digit PIN</label>
              </div>
            </div>
            {errors.pin && touched.pin ? (
              <span style={{ color: "red" }}>{errors.pin}</span>
            ) : null}
            <Buttons
              value="Continue"
              type="submit"
              name="submit"
              disabled={isSubmit}
              className="btn btn-info mt-2" />
          </form>

        </div>
      </div>}
      {
        loginMode === 2 && <ForgotPinScreen1 setLoginMode={setLoginMode} mobile={msisdnFromHeaders} />
      }
    </>
  );
};

export default Login;
