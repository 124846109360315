import React, { useState } from 'react'
import TextField from "@mui/material/TextField";
import Buttons from "../Components/Buttons";
import { useFormik } from "formik";
import axios from "axios";
import { baseUrl } from "../constant";
import { HelpValidation } from "../Components/Yup";
import toast, { Toaster } from "react-hot-toast";
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';

export default function ContactUs() {
    const [isSubmit, setIsSubmit] = useState(false);
    const formInitialValues = {
        name: "",
        phonenumber: "",
        email: "",
        message: "",
    };
    const {
        handleChange,
        values,
        touched,
        errors,
        handleBlur,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: formInitialValues,
        validationSchema: HelpValidation,

        onSubmit: async (values) => {
            Notiflix.Loading.circle()
            try {
                const response = await axios.post(
                    `${baseUrl}/user/contact_us`,
                    {
                        name: values.name,
                        email: values.email,
                        message: values.message,
                        mobile: String(values.phonenumber),
                    },

                );

                if (response.data.success) {
                    resetForm(formInitialValues);
                    // // console.log(response.data)
                    toast.success(response.data.message, { duration: 4000 })
                    Notiflix.Loading.remove();
                } else {
                    toast.error(response.data.data.message, { duration: 4000 })
                    Notiflix.Loading.remove();
                }
            } catch (error) {

                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }
                toast.error(error.response.data.message);

                Notiflix.Loading.remove();

            }
        },
    });
    return (
        <div>
            <Toaster />
            <div className="contactus">
                <img src="images/contactus.png" className="w-100" alt="contactus" />
            </div>

            <div className='details mb-3'>
                <h1 className="px-4">Fill the details</h1>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="mt-2 px-4">
                        <TextField
                            label="Name"
                            variant="outlined"
                            type="text"
                            className="mt-2 w-100"
                            name="name"
                            onBlur={handleBlur}


                            onChange={(e) => {
                                const alphaValue = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                                handleChange({
                                    target: {
                                        name: 'name',
                                        value: alphaValue,
                                    },
                                });
                            }}
                            value={values.name}
                        />
                        {errors.name && touched.name ? (
                            <span style={{ color: "red" }}>{errors.name}</span>
                        ) : null}
                        <TextField
                            label="Phone number"
                            variant="outlined"
                            type="text"
                            className="mt-2 w-100"
                            name="phonenumber"
                            onBlur={handleBlur}
                            onChange={(e) => {
                                const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                                handleChange({
                                    target: {
                                        name: 'phonenumber',  // Fix: Use 'phonenumber' instead of 'number'
                                        value: numericValue,
                                    },
                                });
                            }}
                            value={values.phonenumber}
                        />
                        {errors.phonenumber && touched.phonenumber ? (
                            <span style={{ color: "red" }}>{errors.phonenumber}</span>
                        ) : null}
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            className="mt-2 w-100"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                        />
                        {errors.email && touched.email ? (
                            <span style={{ color: "red" }}>{errors.email}</span>
                        ) : null}
                        <TextField
                            id="outlined"
                            label="Message"
                            className="mt-2 w-100"
                            multiline
                            minRows={6}
                            name="message"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.message}
                        />
                        {errors.message && touched.message ? (
                            <span style={{ color: "red" }}>{errors.message}</span>
                        ) : null}
                        <Buttons
                            value="SUBMIT"
                            type="submit"
                            name="submit"
                            disabled={isSubmit}
                            className="btn btn-success submitmail  mt-2"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
