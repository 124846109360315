import axios from 'axios';
import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../constant';
import { v4 as uuidv4 } from 'uuid';

const generateUuid = () => {
    return uuidv4();
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function MtnProceed() {

    let navigate = useNavigate();
    let router = useQuery();

    const [user, setUser] = useState({});

    return (
        <>
            <div className='mc-page-container'>
                <header className='mc-game-header'>
                    <img src='images/logoimg.png' alt='' className='mc-logo' />
                </header>
                <section className='mc-section-proceed'>

                    <div>
                        <h3>Welcome</h3>
                        {/* <h2>{user ? user?.mobile : ""}</h2> */}
                    </div>
                    <div className='logo-container'>
                        <img src='images/wheellogo.png' alt='' />
                    </div>

                    <div className='btn-container'>

                        <a id="secureDLink" style={{ textDecoration: "none", color: "black" }} href={`http://ng-app.com/NinaJojer/spin4cash-landing-en-doi-web?origin_banner=2&trxId=${generateUuid()}&trfsrc=googleads`}>
                            <button
                                className='mc-proceed-btn'
                                onClick={() => { Notiflix.Loading.circle() }}

                            >
                                Click to Proceed
                            </button>
                        </a>
                    </div>
                </section>
            </div>
        </>
    )
}
