import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../constant";
import Notiflix from 'notiflix';
export default function WinnerList() {
  const [data, setData] = useState([]);

  // Function to format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    Notiflix.Loading.circle()
    axios
      .get(`${baseUrl}/user/spin_history`, {})
      .then((response) => {
        // console.log("🚀 ~ .then ~ response:", response.data.spin_history);
        setData(response.data.spin_history);
        Notiflix.Loading.remove();
      })
      .catch((error) => {
        // console.log(error);
        Notiflix.Loading.remove();
      });
  }, []);

  return (
    <>
      <h3 className="text-center">Recent winners</h3>
      <p className="text-center"> This is a list of the recent winners!</p>
      {data.map((data, index) => (
        <div key={index} className="d-flex tableparent">
          {/* <div className="table">
            <p>S.no.</p>
            <p>{index + 1}</p>
          </div> */}
          <div className="table">
            <p>Date</p>
            <p>{formatDate(data.current_date)}</p>
          </div>
          <div className="table">
            <p>Amount</p>
            <p>{data.win_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
          </div>
          <div className="table">
            <p>Phone Number</p>
            <p>{data.userDetail.mobile.slice(0, -3) + 'xxx'}</p>
          </div>

        </div>
      ))}

      <style>
        {`

    .tableparent {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    background: #FFD345;

  }
  
  .table {
    padding: 5px;
    flex: 1;
    text-align: center;
    border-right: 1.9px dashed #000000;
  }
  
  .table:last-child {
    border-right: none;
  }
      `}
      </style>
    </>
  );
}
