import React from 'react';

export default function Buttons({ value, className, onClick, type, name, ref, disabled }) {
    return (
        <button className={`buttons ${className}`} onClick={onClick} type={type} name={name} ref={ref} disabled={disabled} onFocusCapture={(e) => {
            e.target.style.opacity = 0.8;
        }}>
            {value}
        </button>
    );
}


