import React from 'react'
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function Congrates() {
    const navigate = useNavigate();
    return (
        <div className="congrates">
            <div className="navigater">
                <IoClose onClick={() => navigate("/spin-wheel")} />
            </div>

            <div className='text'>
                <div className="content">
                    {/* <h2>SENT</h2>
                    <p> Your withdrawal request has been forwarded for processing. You will be notified of the status by SMS within 24hrs.
                    </p> */}

                    <img width={`100%`} src='images/payment_done.jpeg' alt='' />
                </div>
            </div>
        </div>
    )
}
