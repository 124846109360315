
import * as Yup from "yup";

const Validate = Yup.object({
    name: Yup.string().min(3).max(20).required("*Name is required"),
    number: Yup.string()
        .matches(/^0\d{10}$/, "*Number must be exactly 11 digits and start with 0")
        .required("*Number is required"),
    termsAndConditions: Yup.boolean().oneOf([true], '*You must accept the terms and conditions'),
    enter_pin: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .required("*PIN is required"),
    verify_pin: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .oneOf([Yup.ref('enter_pin')], '*PINs must match')
        .required("*PIN is required")
});

const Validation = Yup.object({
    number: Yup.string()
        .matches(/^0\d{10}$/, "*Number must be exactly 11 digits and start with 0")
        .required("*Number is required"),
    pin: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .required("*PIN is required"),
});

const ValidationOtp = Yup.object({
    otp: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .required("*PIN is required"),
});


const ValidationForgotPinScreen1 = Yup.object({
    number: Yup.string()
        .matches(/^0\d{10}$/, "*Number must be exactly 11 digits and start with 0")
        .required("*Number is required"),
});

const Profiles = Yup.object({
    username: Yup.string().min(3).max(20).required("*Username is required"),
});
const Verifications = Yup.object({
    withdraw: Yup.string().min(2, "*Number must be at least 2 digit").required("*Number is required"),
});

const HelpValidation = Yup.object({
    name: Yup.string().min(3).max(20).required("*Name is required"),
    phonenumber: Yup.string()
        .matches(/^0\d{10}$/, "*Number must be exactly 11 digits and start with 0")
        .required("*Number is required"),
    email: Yup.string().email().required("*Email is required"),
    message: Yup.string().min(3).required("*Message is required"),
});

const ValidatePinReset = Yup.object({
    enter_pin: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .required("*PIN is required"),
    verify_pin: Yup.string()
        .matches(/^\d{4}$/, "*PIN must be exactly 4 digits")
        .oneOf([Yup.ref('enter_pin')], '*PINs must match')
        .required("*PIN is required"),
    otp: Yup.string().matches(/^\d{4}$/, "*PIN must be exactly 4 digits").required(),
    user_name: Yup.string().optional()
});

export { Validate, Validation, Profiles, Verifications, HelpValidation, ValidationForgotPinScreen1, ValidatePinReset, ValidationOtp };
