import React from 'react';
import { useFormik } from "formik";
import { IoCallOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Buttons from "../Components/Buttons";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from "../constant";
import serviceFunctions from '../Services';
import Notiflix from 'notiflix';

const BankVerification = () => {

    const navigate = useNavigate();
    let token = localStorage.getItem("token");
    const formInitialValues = {
        bankaccno: "",
        bankcode: "",
    };
    const { handleChange, values, handleBlur, handleSubmit } =
        useFormik({
            initialValues: formInitialValues,

            onSubmit: async (values) => {

                Notiflix.Loading.circle();

                const req = await axios.post(`${baseUrl}/user/resolve`, {
                    account_number: values.bankaccno,
                    bank_code: values.bankcode

                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        // console.log(response, "gjgshjhfgrh");
                        Notiflix.Loading.remove();
                        if (response.data.success) {
                            toast.success(response.data.message, { duration: 3000 })

                            setTimeout(() => navigate('/add-bank'), 3000);

                        } else {
                            toast.error(response.data.message, { duration: 4000 })
                        }

                    })
                    .catch(error => {

                        Notiflix.Loading.remove();

                        if (error?.response?.status == 401) {
                            serviceFunctions.handleLogout();
                        }

                        toast.error("An error occurred. Please try again.", { duration: 4000 })
                    });
            },
        });

    return (
        <>
            <div className="subscribe">
                <Toaster />
                <div className="navigater">
                    <FaArrowLeftLong onClick={() => navigate(-1)} />
                    <p onClick={() => navigate("/help")}>Help</p>
                </div>
                <div className="msisdn">
                    <p> Bank Account Verification</p>
                </div>
                <div className="userDetails mb-3">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="formSpaces1 mt-2">

                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Account Number"
                                    name="bankaccno"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bankaccno}
                                />
                                <label> Bank Account Number</label>
                            </div>


                        </div>
                        <div className="formSpaces1 mt-2">

                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Code"
                                    name="bankcode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bankcode}
                                />
                                <label> Bank Code</label>
                            </div>


                        </div>

                        <Buttons
                            value="Continue"
                            type="submit"
                            name="submit"
                            className="btn btn-info mt-2" />
                    </form>
                </div>
            </div>
        </>
    );
};

export default BankVerification;

