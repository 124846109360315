import axios from 'axios';
import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../constant';
import { v4 as uuidv4 } from 'uuid';

const generateUuid = () => {
    return uuidv4();
};

export default function ClickToProceed() {

    const [isLoaded, setIsLoaded] = useState("");

    useEffect(() => {

        const interval = setTimeout(() => {
            Notiflix.Loading.circle();
            handleTokenVerification();
        });

        return () => clearInterval(interval);

    }, []);


    const handleTokenVerification = async () => {
        try {

            Notiflix.Loading.circle();

            let res = await axios.get(`${baseUrl}/user/user_profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (res.data) {
                console.log(res.data.data);
                localStorage.setItem("mobile", res.data.data.mobile);
                localStorage.setItem("network", res.data.data.network);
                Notiflix.Loading.remove();
                setIsLoaded(true);
            }

        } catch (err) {
            Notiflix.Loading.remove();
            navigate("/login");
            return err.message;
        }
    }

    let navigate = useNavigate();

    let mobile = localStorage.getItem("mobile");
    return (
        <>

            {isLoaded && <div className='mc-page-container'>
                <header className='mc-game-header'>
                    <img src='images/logoimg.png' alt='' className='mc-logo' />
                </header>
                <section className='mc-section-proceed'>

                    <div>
                        <h3>Welcome</h3>
                        <h2>{mobile && mobile}</h2>
                    </div>
                    <div className='logo-container'>
                        <img src='images/wheellogo.png' alt='' />
                    </div>

                    <div className='btn-container'>

                        <a style={{ textDecoration: "none", color: "black" }} href={false ? `https://ng-app.com/NinaJojer/spin4cash-on-demand-landing-en-doi-web?origin_banner=3&trxId=${generateUuid()}&trfsrc=secureD` : `/spin-wheel`}>
                            <button
                                className='mc-proceed-btn'
                            >Click to Proceed</button>
                        </a>
                    </div>
                </section >
            </div >}
        </>
    )
}
