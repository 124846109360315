import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../constant';
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';

export default function WithdrawHistory() {
    const token = localStorage.getItem("token");
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    // console.log(data)
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        Notiflix.Loading.circle()
        axios.get(`${baseUrl}/user/withdraw_history`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            setData(response.data.Withdraw_History);
            // console.log(response.data.Withdraw_History);
            Notiflix.Loading.remove();
        }).catch((error) => {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            console.error("Error fetching withdraw history:", error.message);
            Notiflix.Loading.remove();
        });
    }, [token]);

    return (
        <>
            <div className='WithdrawAmount'>
                <FaArrowLeftLong onClick={() => navigate(-1)} className='close' />
                <div className="mt-2 title">
                    <p>Withdraw history</p>
                </div>
                {data.map((item, index) => (
                    <div key={item.id || index} className='d-flex tableparent'>
                        <div className='table'>
                            <p>S.no.</p>
                            <p>{index + 1}</p>
                        </div>
                        <div className='table'>
                            <p>Date</p>
                            <p>{formatDate(item.created_at)}</p>
                        </div>
                        <div className='table'>
                            <p>Amount</p>
                            <p>N {item.withdraw_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                        </div>
                        <div className='table'>
                            <p>Status</p>
                            <p style={{ color: getStatusColor(item.status) }}>
                                {item.status === 0 ? 'Failed' : 'Done'}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

function getStatusColor(status) {
    switch (status) {
        case '1':
            return '#109E38';

        case '0':
            return '#FF0000';

        default:
            return '';

    }
}
