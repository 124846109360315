import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';

export default function LinearDeterminate() {
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulating a fetch request, replace with the actual URL of the target page
        const response = await fetch('/firstspin');
        if (response.ok) {
          // Redirect to another page when progress reaches 100%
          navigate('/home');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          fetchData();
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
