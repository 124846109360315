import React from 'react'
import { Toaster } from 'react-hot-toast'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import GloSubscription from '../Components/Subsciptions/Glo';

export default function Plans() {

    let navigate = useNavigate();



    return (
        <div className='buy_spins'>
            <Toaster />
            <FaArrowLeftLong onClick={() => navigate(-1)} className='close' />
            <div className="mt-2 title">
                <p>Buy More Spins</p>

            </div>

            <GloSubscription />

        </div>
    )
}
