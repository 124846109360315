import Buttons from "../Components/Buttons";
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import { baseUrl } from "../constant";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import serviceFunctions from "../Services";
export default function SpinWheel() {

    const [prize, setPrize] = useState([])
    const [prizesId, setprizesId] = useState(null)
    const [isSpinning, setIsSpinning] = useState(false);
    const [rotationCount, setRotationCount] = useState(1);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [showCountdown, setShowCountdown] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false)
    const [targetRotation, setTargetRotation] = useState(0);
    const [prizeId, setPrizeId] = useState(66);
    const [stopMessage, setStopMessage] = useState("Try with three trials");
    const audioRef = useRef(new Audio("spinsound.m4a"));
    const navigate = useNavigate();
    const Suscribe = () => {
        navigate("/subscription");
    };

    const startCountdown = (seconds) => {
        setCountdown(seconds);
        setShowCountdown(true);

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(interval);
            setCountdown(0);
            setShowCountdown(false);
            setBtnDisable(false)
        }, seconds * 1000);
        setBtnDisable(true);
    };

    useEffect(() => {
        // console.log({ prizeId });
    }, [prizeId])

    const handleVisibilityChange = () => {
        if (document.hidden) {
            audioRef.current.muted = true;
        } else {
            audioRef.current.muted = false;
            if (isAudioPlaying) {
                audioRef.current.play();
            }
        }
    };


    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [isAudioPlaying]);

    let keyframesStyle;

    function createKeyframes(name, steps) {
        keyframesStyle = document.createElement("style");
        keyframesStyle.innerHTML = `
          @keyframes ${name} {
            ${steps.map((step, i) => `${i * 100 / steps.length}% { transform: rotate(${step}deg); }`).join("")}
          }
        `;
        document.head.appendChild(keyframesStyle);
    }

    function removeKeyframes() {
        if (keyframesStyle) {
            document.head.removeChild(keyframesStyle);
        }
    }


    const allPrize = async () => {

        try {


            await axios.get(`${baseUrl}/user/all_prizes`).then((response) => {
                // console.log(response)
                const prizes = response?.data.result.map(prizeObj => `N${prizeObj.prizes}`);
                const prizesId = response?.data.result.map(prizeObj => prizeObj.id);


                setPrize(prizes)
                setprizesId(prizesId)
                // console.log(prizes)
                // console.log(prizesId)
            }).catch((err) => {

                if (err?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                // console.log(err)
            })
        } catch (err) {

        }

    }

    useEffect(() => {
        allPrize();
    }, [])



    const wheelRef = useRef(null);
    const spinBtnRef = useRef(null);
    const stopBtnRef = useRef(null);
    const finalValueRef = useRef(null);
    const myChartRef = useRef(null);
    const finalPrizeRef = useRef(null)

    useEffect(() => {
        const data = [16, 16, 16, 16, 16, 16];
        const pieColors = [
            "#faa629",
            "#e65051",
            "#39a0e8",
            "#2eae74",
            "#c93c77",
            "#b163da",
        ];

        myChartRef.current = new Chart(wheelRef.current, {
            plugins: [ChartDataLabels],
            type: "pie",
            data: {
                labels: [...prize],
                datasets: [
                    {
                        backgroundColor: pieColors,
                        data: data,
                    },
                ],
            },
            options: {
                responsive: true,
                animation: { duration: 0 },
                plugins: {
                    tooltip: false,
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        rotation: function (ctx) {
                            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                            const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
                            return rotation < 180 ? rotation - 90 : rotation + 90;
                        },
                        color: "#ffffff",
                        formatter: (_, context) =>
                            context.chart.data.labels[context.dataIndex],

                        textAlign: 'center',

                        font: {
                            weight: 'bold',
                            size: 15,
                        }
                    },
                },
            },
        });

        return () => {
            myChartRef.current.destroy();
        };
    }, [prize]);

    const valueGenerator = (angleValue, targetPrizeId) => {

        let rotationValues = [];

        for (let i = 0; i < prize.length; i++) {
            const minDegree = (i * (360 / prize.length));
            const maxDegree = (((i + 1) * (360 / prize.length)) - 1);
            const value = prize[i];
            const prizeId = prizesId[i];

            rotationValues.push({ minDegree, maxDegree, value, prizeId });
        }




        // console.log(rotationValues, "rotatonalValue")
        // console.log("prizeId", targetPrizeId);
        // console.log(rotationValues.filter(el => el.prizeId === targetPrizeId));

        finalValueRef.current.innerHTML = `You Won: ${rotationValues.filter(el => el.prizeId == targetPrizeId)[0]?.value}`;
        finalPrizeRef.current = `${rotationValues.filter(el => el.prizeId == targetPrizeId)[0]?.prizeId}`;



    };


    const spinBtnClickHandler = () => {

        setIsSpinning(true);
        finalValueRef.current.innerHTML = '';

        const toggleAudio = () => {
            if (isAudioPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
                audioRef.current.muted = false; // Unmute the audio
            }
            setIsAudioPlaying(isAudioPlaying);
        };
        toggleAudio();

        const randomIndex = Math.floor(Math.random() * prizesId.length);
        // console.log(randomIndex, "randomiNDEX")
        const targetPrizeId = prizesId[randomIndex];
        // console.log(targetPrizeId, "hello");
        setPrizeId((prevPrizeId) => targetPrizeId);
        setTargetRotation(calculateTargetRotation(targetPrizeId));
        const rotatingdeg = 360 * (20 * rotationCount) + calculateTargetRotation(targetPrizeId);

        setRotationCount(rotationCount + 1);
        handleStopSpin(rotatingdeg)

        const wheel = document.getElementById("wheel");
        function rotateWheel(degrees) {
            wheel.style.animationName = "rotate";
            wheel.style.animationDuration = "20s";
            wheel.style.animationIterationCount = "1";
            wheel.style.animationPlayState = "running";
            wheel.style.transform = `rotate(${degrees}deg)`;
        }

        createKeyframes(`rotate`, [0, rotatingdeg]);
        rotateWheel(rotatingdeg);

        setTimeout(() => {
            removeKeyframes();
            valueGenerator(calculateTargetRotation(targetPrizeId), targetPrizeId);
            setIsSpinning(false);
            // console.log("now you are ready");
            if (rotationCount === 1) {
                setStopMessage("You have 2 trail left")
            } else if (rotationCount === 2) {
                setStopMessage("You have 1 trail left")
            } else if (rotationCount === 3) {

                navigate("/trial-info");
            }
            else {
                setStopMessage("Your trials are over");
            }
        }, 7000);

    };


    const calculateTargetRotation = (targetPrizeId) => {
        const minValue = 5;
        const maxValue = 30;
        const randomValue = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
        const index = prizesId.indexOf(targetPrizeId);
        const targetRotation = 360 - (60 * (index + 1)) + randomValue;
        return targetRotation;
    };

    const handleStopSpin = (degrees) => {

        if (isSpinning) {
            removeKeyframes();
            if (!showCountdown) {
                startCountdown(7);
                setBtnDisable(true);
            }
            const wheel = document.getElementById("wheel");
            wheel.style.animationPlayState = "paused";
            wheel.style.transform = `rotate(${degrees}deg)`;
            wheel.style.animationPlayState = "running";
            wheel.style.animationDuration = "2s";
            valueGenerator(targetRotation, prizeId);
            setIsSpinning(false);

            const toggleAudio = () => {
                if (isAudioPlaying) {
                    audioRef.current.pause();
                } else {
                    audioRef.current.play();
                    audioRef.current.muted = true; // Unmute the audio
                }
                setIsAudioPlaying(isAudioPlaying);
            };
            toggleAudio();
        }
    }

    return (
        <>
            <div className="spinWheel">
                <IoClose
                    className={`close ${(isSpinning || btnDisable) ? 'unclickable' : ''} `}
                    onClick={() => !(isSpinning || btnDisable) && navigate(-1)}
                />
                <div className=" d-flex justify-content-center">


                    <div className="gamelogo">
                        <img src="images/logoimg.png" alt={"gamelogo"} />
                    </div>

                </div>

                <div className="logobox"></div>
                <div className="content">
                    <div className="winnerPrize">
                        <strong><i><span ref={finalValueRef}></span></i></strong>
                    </div>
                </div>
                <div className="fullwheel mb-2">
                    <div className="containers">
                        <div className="box mc-rotate-box" >
                            <canvas ref={wheelRef} id="wheel"></canvas>

                            <img src="images/spinner.png" alt="spinner" className="spinners" />
                            <img src="images/wheelwidth.png" alt="wheelouter" className="wheelouter" />
                        </div>
                    </div>

                </div>
                <div className="spinscount d-flex justify-content-center">
                    <i>
                        <strong>
                            {stopMessage}
                        </strong>
                    </i>

                </div>
                <div className="spinWheelBtn">
                    {showCountdown && (
                        <div className="text-center">
                            Next Spin: 00:0{countdown}
                        </div>
                    )}

                    <button ref={spinBtnRef} value="Tap to Spin" className="btn btn-info mb-1 tapping"
                        onClick={spinBtnClickHandler} disabled={isSpinning || btnDisable} >
                        Tap to Spin
                    </button>

                    <button
                        value="Stop Spin"
                        className="btn btn-warning mb-1 tapping"
                        ref={stopBtnRef}
                        disabled={!isSpinning}
                        onClick={handleStopSpin}

                    >
                        Stop Spin
                    </button>
                    <Buttons
                        value="Register"
                        className="btn btn-warning mb-1 resgister"
                        onClick={Suscribe}
                        disabled={isSpinning || btnDisable}
                    />


                </div>
            </div >
        </>
    )
}