import React from 'react'

export default function TermsCondition() {
    return (
        <div>
            <ol >
                <li>By participating, you confirm that you are 18 years of age or older, making you eligible to engage in the game.</li>
                <li>Your involvement in the game implies your acceptance of these terms and conditions, which will be interpreted at the sole discretion of Benedizione Limited and the Network Operator. Their decision on any disputes will be final and binding.</li>
                <li>Any information related to this promotion found on promotional or advertising materials in any media, either before or during the promotional period, will be considered part of the entry terms and conditions.</li>
                <li>Employees of the network are not eligible to participate in the game or claim prizes.</li>
                <li>Winners will receive an SMS notification informing them of their win and can then proceed to withdraw the prize to a bank account.</li>
                <li>To withdraw winnings, winners must log into Spin4cash.co and make a withdrawal request from their Game wallet to a bank account.</li>
                <li>Winners without a bank account can open a mobile money account with their network and transfer the money there.</li>
                <li>Each player may register only one bank account for cash prize withdrawals. Changing a registered bank account will take a minimum of 30 days.</li>
                <li>All prizes will be disbursed within a maximum of 48 hours from the time of withdrawal from the Prize Wallet to the Bank Account.</li>
                <li>If Benedizione Limited or the Network determines that a winner has fraudulently obtained a prize, the prize money will not be paid to the winner.</li>
                <li>Should Benedizione Limited or the Network, after paying the prize money, discover fraudulent activity by the winner, they will take all necessary measures to recover the amounts paid, including legal action.</li>
                <li>Prizes are subject to change at the discretion of Benedizione Limited or the Network, or their respective service providers.</li>
                <li>Available prizes include N1,000, N10,000, N100,000, and N1,000,000.</li>
                <li>Winners consent to the use of their MSISDN, pictures, and testimonies for marketing purposes in promoting the game.</li>
                <li>You acknowledge that this is a game of chance, and participating does not guarantee winning a prize.</li>
                <li>You are responsible for playing the game in moderation and agree to the measures in place to prevent addiction, which may include restrictions on gameplay after reaching a certain threshold.</li>
            </ol>
        </div>
    )
}
