import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './Layout';
import LoadingPage from './Pages/LoadingPage';
import Home from './Pages/Home';
import Subscription from './Pages/Subscription';
import Verification from './Pages/Verification';
import SpinWheel from './Pages/SpinWheel';
import Profile from './Pages/Profile';
import Wallet from './Pages/Wallet';
import Addbank from './Pages/Addbank';
import Login from './Pages/Login';
import ProtectedRoute from './ProtectedRoute';
import UserTrial from './Pages/UserTrial';
import TrialOver from './Pages/TrialOver';
import WithdrawAmount from './Pages/WithdrawAmount'
import Help from './Pages/Help';
import BankVerification from './Pages/BankVerification';
import WithdrawVerification from './Pages/WithdrawVerification';
import Congrates from './Pages/Congrates';
import WithdrawHistory from './Pages/WithdrawHistory';
import ContactPage from './Pages/ContactPage';
import BuySpins from './Pages/BuySpins';
import Confirmation from './Pages/Confirmation';
import Headers from './Pages/Headers';
import ClickToProceed from './Components/ClickToProceed';
import Auth from './Pages/Auth';
import { elements } from 'chart.js';
import Plans from './Pages/Plans';
import MtnProceed from './Components/MtnClickProceed';


const routes = [
    { path: 'spin-wheel', element: <SpinWheel /> },
    { path: 'profile', element: <Profile /> },
    { path: 'my-wallet', element: <Wallet /> },
    { path: 'add-bank', element: <Addbank /> },
    { path: 'withdraw', element: <WithdrawAmount /> },
    // { path: 'bank-verification', element: <BankVerification /> },
    // { path: 'withdraw-verification', element: <WithdrawVerification /> },
    { path: 'congrats', element: <Congrates /> },
    { path: 'withdraw-history', element: <WithdrawHistory /> },
    { path: 'buy-spins', element: <BuySpins /> },
    { path: 'auth', element: <Auth /> }
];

const App = () => {

    const VerifiedRoute = ({ children }) => {

        let value = window.location.href.split('=')[1];

        let route = window.location.href.split('/')[3].split("?")[0];

        // // console.log(value, route);

        let mobileNum = localStorage.getItem('mobile');
        let token = localStorage.getItem('token');

        if (!mobileNum || !token) {
            return children;
        } else if (window.location.href.split('=')[1] && route == "login") {
            if ((mobileNum == value))
                return children;
            else {
                localStorage.setItem('mobile', value?.startsWith('234') ? `0${value?.slice(3)}` : `${value}`);
                return children;
            }

        } else {
            return <Navigate to="/spin-wheel" />;
        }
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<VerifiedRoute><LoadingPage /></VerifiedRoute>} />
                    <Route path='home' element={<VerifiedRoute><Home /></VerifiedRoute>} />
                    <Route path='subscription' element={<VerifiedRoute><Subscription /></VerifiedRoute>} />
                    <Route path='login' element={<VerifiedRoute><Login /></VerifiedRoute>} />
                    <Route path='verification' element={<VerifiedRoute><Verification /></VerifiedRoute>} />
                    <Route path='confirmation' element={<Confirmation />} />
                    <Route path='plans' element={<Plans />} />
                    <Route path='proceed' element={<ClickToProceed />} />
                    <Route path='mtn' element={<MtnProceed />} />
                    {routes.map(({ path, element }) => (
                        <Route key={path} path={path} element={<ProtectedRoute>{element}</ProtectedRoute>} />
                    ))}
                    <Route path='headers' element={<Headers />} />
                    <Route path="user-trial" element={<VerifiedRoute><UserTrial /></VerifiedRoute>} />
                    <Route path="trial-info" element={<VerifiedRoute><TrialOver /></VerifiedRoute>} />
                    <Route path='help' element={<Help />}></Route>
                    <Route path='contact-us' element={<ContactPage />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
